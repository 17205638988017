import React from 'react';

import type { PhotoStoreConfigProps } from '@dexter/photo-store';
import { usePhotoStores } from '@dexter/photo-store';

import Gallery from './components/Gallery';
import { SEARCH_API_DEV, SEARCH_API_PROD } from './CONSTANTS';
import { useAuth } from '../../AuthProvider';

export default function PhotoStore(configuration: PhotoStoreConfigProps) {
    const { accessToken } = useAuth();
    const authToken = accessToken?.startsWith('Bearer') ? accessToken : `Bearer ${accessToken}`;
    const url = process.env.NODE_ENV === 'production' ? SEARCH_API_PROD : SEARCH_API_DEV;

    if (configuration.providers && configuration.providers.depositPhoto) {
        // TODO: fix this
        // eslint-disable-next-line no-param-reassign
        configuration.providers.depositPhoto.searchApi = url;
    }

    const data = usePhotoStores(configuration, authToken ?? '');
    const dpData = Object.values(data);

    if (data.loading) {
        return <div>Loading...</div>;
    }

    return <div>{dpData && dpData.length > 0 && <Gallery data={data} />}</div>;
}
