import React from 'react';
import { Link, Outlet } from 'react-router-dom';

import { basePath } from './appSettings';

import './App.css';

export function App() {
    return (
        <>
            <header>
                <nav className="home_nav">
                    <ul>
                        <li>
                            <Link to={basePath}>Home</Link>
                        </li>
                        {/* <li>
                           <Link to="docs/preview">Preview Package Documentation</Link>
                        </li> */}
                        <li>
                            <Link to="docs/fusion-preview">Fusion Preview Documentation</Link>
                        </li>
                        <li>
                            <Link to="demo/photo-store">Photo Store Demo</Link>
                        </li>
                        {/* <li>
                            <Link to="demo/preview">Preview Demo</Link>
                        </li>
                        <li>
                            <Link to="demo/instagram">Instagram Demo</Link>
                        </li> */}
                        <li>
                            <Link to="demo/gallery">Gallery Demo</Link>
                        </li>
                        <li>
                            <Link to="demo/fusion-preview">Fusion Preview Demo</Link>
                        </li>
                        <li>
                            <Link to="demo/text-to-image">Text to Image Demo</Link>
                        </li>
                    </ul>
                </nav>
            </header>
            <Outlet />
        </>
    );
}

export default App;
