import React, { useMemo } from 'react';

import type { SearchDataProps } from '@dexter/image-generation-sdk';
import { useTextToImage } from '@dexter/image-generation-sdk';

import { useAuth } from '../../AuthProvider';

// TODO: We should update the appropriate type here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function TextToImage(data: any) {
    const searchQuery = data.search;
    const { accessToken } = useAuth();
    const submitCount = data.count;
    const searchData: SearchDataProps = useMemo(
        () => ({
            searchQuery,
            submitCount,
            noOfSampleImages: 1,
            format: 'Square',
        }),
        [searchQuery, submitCount],
    );
    const { loading, result } = useTextToImage(
        searchData,
        true,
        accessToken?.startsWith('Bearer') ? accessToken : `Bearer ${accessToken}`,
    );

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {result && (
                <div>
                    <pre style={{ overflow: 'auto' }}>{JSON.stringify(result, undefined, 2)}</pre>
                </div>
            )}
            {result &&
                result.data &&
                result.data.length > 0 &&
                result.data.map((images) => <img src={images.preview} />)}
        </>
    );
}
