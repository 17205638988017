import { uniqueId } from 'lodash';
import React, { useId, useState } from 'react';

import { css } from '@emotion/css';

import { Button } from '@dexter/dex-component-library';
import type { Asset, GalleryProps, IconsGalleryProps, Images, LayoutImages } from '@dexter/gallery';
import { Gallery } from '@dexter/gallery';

import { langs } from './localization';

const baseStyle = css`
    background-color: #fff;
    padding: 50px;
    margin: 30px;
    height: auto;

    h2 {
        font-size: 24px;
    }
    .configuration {
        margin-bottom: 20px;
    }
    .field {
        margin-bottom: 10px;
        max-width: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        input,
        select {
            padding: 10px 15px;
            border-radius: 10px;
            border: 1px solid;
            min-width: 180px;
            box-sizing: border-box;
        }
    }
    .testGallery {
        width: 450px;
    }
`;

export function GalleryParams() {
    const [showGallery, setShowGallery] = useState(false);
    const [galleryHeight, setGalleryHeight] = useState(150);
    const [visibleImages, setVisibleImages] = useState(2);
    const [layoutGallery, setLayoutGallery] = useState('horizontal_dynamic');
    const [selectedLanguage, setSelectedLanguage] = useState('en-us');
    const [allImagesLoaded, setAllImagesLoaded] = useState(false);
    const [loadNewImages, setLoadNewImages] = useState(false);
    const id = useId();
    const images: Asset[] = [
        {
            fileName: 'Attribuzione codice fiscale.pdf',
            fileSizeBytes: 99709,
            id: '',
            loadingState: null,
            originalFileUrl: 'https://api.sherbert.cimpress.io/v2/assets/rDN9qRXmQ8HVorYY8PNmy~200',
            pages: [
                {
                    id: 'li1t73szzp6ug5x',
                    previewUrl:
                        'https://api.sherbert.cimpress.io/v2/assets/rDN9qRXmQ8HVorYY8PNmy~200/webPreview/1?signature=142996910379a0703148853e543b4ebc710c70d3&showDeleted=false',
                    loadingState: null,
                    originalFileUrl: 'https://api.sherbert.cimpress.io/v2/assets/rDN9qRXmQ8HVorYY8PNmy~200',
                    pageNumber: 1,
                },
            ],
            previewUrl:
                'https://api.sherbert.cimpress.io/v2/assets/OBAF4hEuUREt_6XwbCq5L~200/webPreview?signature=1b47344da984e20f49b657a1dc3dd55ba7e30dc2',
            uploadDate: '24/5/2023',
        },
        {
            id: '',
            originalFileUrl: 'https://api.sherbert.cimpress.io/v2/assets/GLJGbLt6vcKsLrerDMzyC~300',
            fileName: 'Foto Photo Photo eVisa.jpg',
            loadingState: null,
            fileSizeBytes: 875160,
            uploadDate: '19/4/2023',
            previewUrl:
                'https://api.sherbert.cimpress.io/v2/assets/GLJGbLt6vcKsLrerDMzyC~300/webPreview?signature=2c88852758778e2592a9fbc7fda0adcce678ebf4',
        },
        {
            id: '',
            fileName: 'SLC DELEGA ISCRIZIONE.pdf',
            originalFileUrl:
                'https://api.sherbert.cimpress.io/v2/assets/gMUz8Uw0eE-WbxTrelYEN~200?signature=347a6ee09940f89f5b5bef566617f646b76862fb',
            loadingState: null,
            previewUrl:
                'https://api.sherbert.cimpress.io/v2/assets/gMUz8Uw0eE-WbxTrelYEN~200/webPreview?signature=347a6ee09940f89f5b5bef566617f646b76862fb',
            fileSizeBytes: 351478,
            uploadDate: '9/5/2023',
            pages: [
                {
                    id: 'gMUz8Uw0eE-WbxTrelYEN~200_0',
                    previewUrl:
                        'https://api.sherbert.cimpress.io/v2/assets/gMUz8Uw0eE-WbxTrelYEN~200/webPreview/1?signature=347a6ee09940f89f5b5bef566617f646b76862fb&showDeleted=false',
                    loadingState: null,
                    originalFileUrl:
                        'https://api.sherbert.cimpress.io/v2/assets/gMUz8Uw0eE-WbxTrelYEN~200?signature=347a6ee09940f89f5b5bef566617f646b76862fb',
                    pageNumber: 1,
                    thumbnailUrl:
                        'https://api.sherbert.cimpress.io/v2/assets/gMUz8Uw0eE-WbxTrelYEN~200/thumb/1?signature=347a6ee09940f89f5b5bef566617f646b76862fb&showDeleted=false',
                },
                {
                    id: 'gMUz8Uw0eE-WbxTrelYEN~200_1',
                    previewUrl:
                        'https://api.sherbert.cimpress.io/v2/assets/gMUz8Uw0eE-WbxTrelYEN~200/webPreview/2?signature=347a6ee09940f89f5b5bef566617f646b76862fb&showDeleted=false',
                    loadingState: null,
                    originalFileUrl:
                        'https://api.sherbert.cimpress.io/v2/assets/gMUz8Uw0eE-WbxTrelYEN~200?signature=347a6ee09940f89f5b5bef566617f646b76862fb',
                    pageNumber: 2,
                    thumbnailUrl:
                        'https://api.sherbert.cimpress.io/v2/assets/gMUz8Uw0eE-WbxTrelYEN~200/thumb/2?signature=347a6ee09940f89f5b5bef566617f646b76862fb&showDeleted=false',
                },
                {
                    id: 'gMUz8Uw0eE-WbxTrelYEN~200_2',
                    previewUrl:
                        'https://api.sherbert.cimpress.io/v2/assets/gMUz8Uw0eE-WbxTrelYEN~200/webPreview/1?signature=347a6ee09940f89f5b5bef566617f646b76862fb&showDeleted=false',
                    loadingState: null,
                    originalFileUrl:
                        'https://api.sherbert.cimpress.io/v2/assets/gMUz8Uw0eE-WbxTrelYEN~200?signature=347a6ee09940f89f5b5bef566617f646b76862fb',
                    pageNumber: 1,
                    thumbnailUrl:
                        'https://api.sherbert.cimpress.io/v2/assets/gMUz8Uw0eE-WbxTrelYEN~200/thumb/1?signature=347a6ee09940f89f5b5bef566617f646b76862fb&showDeleted=false',
                },
            ],
        },
        {
            id: '',
            originalFileUrl: 'https://api.sherbert.cimpress.io/v2/assets/bFlJOsOCZGpsvi22c0bgp~300',
            fileName: 'IMG_1919.jpeg',
            loadingState: null,
            fileSizeBytes: 2950493,
            uploadDate: '18/4/2023',
            previewUrl:
                'https://api.sherbert.cimpress.io/v2/assets/bFlJOsOCZGpsvi22c0bgp~300/webPreview?signature=b6026c28c78351c4c91132abbdd64004a23c3ce6',
        },
        {
            id: '',
            originalFileUrl: 'https://api.sherbert.cimpress.io/v2/assets/GLJGbLt6vcKsLrerDMzyC~300',
            fileName: 'Foto eVisa.jpg',
            loadingState: null,
            fileSizeBytes: 875160,
            uploadDate: '19/4/2023',
            previewUrl:
                'https://api.sherbert.cimpress.io/v2/assets/GLJGbLt6vcKsLrerDMzyC~300/webPreview?signature=2c88852758778e2592a9fbc7fda0adcce678ebf4',
            pageNumber: 1,
        },
        {
            id: '',
            originalFileUrl: 'https://api.sherbert.cimpress.io/v2/assets/bFlJOsOCZGpsvi22c0bgp~300',
            fileName: 'IMG_1919.jpeg',
            loadingState: null,
            fileSizeBytes: 2950493,
            uploadDate: '18/4/2023',
            previewUrl:
                'https://api.sherbert.cimpress.io/v2/assets/bFlJOsOCZGpsvi22c0bgp~300/webPreview?signature=b6026c28c78351c4c91132abbdd64004a23c3ce6',
        },
    ];
    images.forEach((image: Asset, index) => {
        // eslint-disable-next-line no-param-reassign
        image.id = `${id}${index}`;
    });
    const [loadedImages, setLoadedImages] = useState(images);
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setGalleryHandler = (event: any) => {
        setShowGallery(false);
        setGalleryHeight(event.target.value);
    };
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setVisibleImagesHandler = (event: any) => {
        setShowGallery(false);
        setVisibleImages(event.target.value);
    };
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setLayoutHandler = (event: any) => {
        setShowGallery(false);
        setLayoutGallery(event.target.value);
    };
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setLanguageHandler = (event: any) => {
        setShowGallery(false);
        setSelectedLanguage(event.target.value);
    };
    const setLoadNewImagesHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setShowGallery(false);
        setLoadNewImages(event.target.value === 'Y');

        if (event.target.value === 'N') {
            setAllImagesLoaded(true);
        }
    };
    const handleShowGallery = () => {
        setShowGallery(true);
    };

    const onRemove = (receivedId: string) => {
        setLoadedImages((prevImages: Images[]) => {
            prevImages.forEach((i: Images, k: number) => {
                if (i.id === receivedId) {
                    prevImages.splice(k, 1);
                }
            });

            return [...prevImages];
        });
    };
    const onAdd = (receivedId: string) => {
        // eslint-disable-next-line no-console
        console.log('add', receivedId);
    };

    const icons: IconsGalleryProps = { add: true, remove: true };
    const obj: GalleryProps = {
        images: loadedImages,
        containerHeight: galleryHeight,
        fetchImagesOnScroll: (params: string) => {
            if (params === 'next') {
                if (!loadNewImages) {
                    if (loadedImages.length === images.length) {
                        setAllImagesLoaded(true);
                    }
                } else {
                    const extraImages = structuredClone(images);
                    extraImages.map((image: Images) => {
                        // eslint-disable-next-line no-param-reassign
                        image.id = uniqueId('dex');
                        // eslint-disable-next-line no-param-reassign
                        image.fileName = `${uniqueId('dex')}${image.fileName}`;

                        return image;
                    });
                    setLoadedImages((prevImages: Images[]) => [...prevImages, ...extraImages]);
                }
            }
        },
        onRemove,
        onAdd,
        icons,
        layout: layoutGallery as LayoutImages,
        allImagesLoaded,
        localization: langs[selectedLanguage as keyof typeof langs],
    };

    return (
        <div className={baseStyle}>
            <h1>Gallery Documentation</h1>
            <div className="configuration">
                <div className="field">
                    <span>Gallery Height</span>
                    <input type="number" value={galleryHeight} id="galleryHeight" onChange={setGalleryHandler} />
                </div>
                <div style={{ display: 'none' }} className="field">
                    <span>Visible Images</span>
                    <input type="number" value={visibleImages} id="visibleImages" onChange={setVisibleImagesHandler} />
                </div>
                <div className="field">
                    <span>Layout</span>
                    <select defaultValue={layoutGallery} onChange={setLayoutHandler}>
                        <option value="horizontal_dynamic">Horizontal Dynamic</option>
                        <option value="horizontal_fixed">Horizontal Fixed</option>
                    </select>
                </div>
                <div className="field">
                    <span>Language</span>
                    <select defaultValue={selectedLanguage} onChange={setLanguageHandler}>
                        <option value="en-us">English</option>
                        <option value="it-it">Italian</option>
                        <option value="fr-fr">French</option>
                    </select>
                </div>
                <div className="field">
                    <span>Load new images</span>
                    <select defaultValue={loadNewImages ? 'Y' : 'N'} onChange={setLoadNewImagesHandler}>
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                    </select>
                </div>
                <div className="field">
                    <span></span>
                    <Button onClick={handleShowGallery}>Load</Button>
                </div>
            </div>
            <div className="testGallery">{showGallery && <Gallery {...obj} />}</div>
        </div>
    );
}
